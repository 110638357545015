<template>
  <header
    class="flex items-center justify-center w-full h-50vh border-b-8 our-products-bg border-primary"
  >
    <div class="font-medium tracking-wide text-center text-white md:w-1/2">
      <h1 class="m-3 text-4xl font-bold uppercase md:text-6xl mt-32">
        {{ $t('Warehouses.h1') }}
      </h1>
      <p class="text-2xl font-medium">{{ $t('Warehouses.description') }}</p>
    </div>
  </header>
  <div class="container mx-auto my-16 sm:px-8 lg:px-20 content-container">
    <div class="text-center">
      <h1 class="text-4xl font-bold text-primary">{{ $t('Warehouses.text_haeader') }}</h1>
    </div>
    <!-- <div
      class="flex flex-wrap-reverse items-center justify-between my-8 md:flex-nowrap md:justify-center"
    > -->
    <div>
      <!-- <div class="w-full p-5 md:w-1/2">
        <img src="../assets/image.png" class="w-full h-96 md:w-1/2" />
      </div> -->

      <div class="warehouses-container">
        <div
          class="warehouse"
          v-for="(warehouse, index) in warehouses"
          :key="warehouse.id"
        >
          <!-- <h2 class="text-3xl text-primary"></h2> -->
          <div class="warehouse-info">
            <p class="warehouse-no">
              {{ $t('Warehouses.warehouse_number') }} {{ index + 1 }}
            </p>
            <p>
              <span class="subtitle"> {{ $t('Warehouses.type') }}: </span>
              {{
                warehouse.status == 'COLD'
                  ? $t('Warehouses.cold_warehouse')
                  : $t('Warehouses.not_cold_warehouse')
              }}
            </p>
            <p>
              <span class="subtitle"> {{ $t('Warehouses.name') }} : </span>
              {{ warehouse.name }}
            </p>
            <p>
              <span class="subtitle"> {{ $t('Warehouses.warehouse_address') }}: </span>
              {{ warehouse.address }}
            </p>
            <div class="location-link-div">
              <a :href="warehouse.location" target="_blank" class="warehouse-location">
                {{ $t('Warehouses.location_on_map') }}
              </a>
            </div>
          </div>
          <div class="warehouse-swiper">
            <swiper
              :slides-per-view="1"
              :loop="true"
              :effect="'fade'"
              :autoplay="{
                delay: 2500,
                disableOnInteraction: false,
              }"
              :pagination="{
                clickable: true,
              }"
              style="'h-full'"
            >
              <swiper-slide
                class="warehouse-swiper-slide"
                v-for="image in warehouse.images"
                :key="image"
              >
                <img :src="URL + image.path" alt="warehouse" />
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwiperCore, { EffectFade, Autoplay, Pagination } from 'swiper'
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'

// install Swiper modules
SwiperCore.use([EffectFade, Autoplay, Pagination])

import { useStore } from 'vuex'
// import { useI18n } from 'vue-i18n'
import { computed } from '@vue/reactivity'
export default {
  name: 'gallery',
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const store = useStore()
    // const Warehouses = computed(() => store.getWarehouses.Warehouses);
    const warehouses = computed(() => store.getters.warehouses)
    console.log('this is the warehouses', warehouses)

    //  const { t, locale } = useI18n({ useScope: 'global' })

    return {
      // URL: process.env.VUE_APP_URL,
      URL: 'https://api.nowarat.ly/',
      warehouses: warehouses || [],
      theImages: ['/warehouse-1.png', '/warehouse-2.jpg'],
      // locale,
      // t,
    }
  },
}
</script>

<style lang="scss" scoped>
header {
  background: url(../assets/map-global-logistics-partnership-import-export-concept.jpg);
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.warehouses-container {
  // display: flex;
  // flex-wrap: wrap;
  // gap: 1rem;
}
.warehouse-no {
  color: #444;
}
.subtitle {
  color: rgb(255, 131, 3);
  font-weight: bold;
}
.warehouses-container .warehouse {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
  padding-bottom: 3rem;
}
.warehouses-container .warehouse:not(:last-child) {
  border-bottom: 1px solid #d8d8d8;
}
.warehouses-container .warehouse > div {
  width: 50%;
}
.warehouses-container .warehouse .warehouse-swiper {
  max-width: 600px;
}
.warehouses-container .warehouse .warehouse-swiper .warehouse-swiper-slide {
  // position: absolute;
  // height: 100%;
  height: auto;
}
.warehouses-container .warehouse .warehouse-swiper img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}
.warehouses-container .warehouse .warehouse-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
}
.warehouses-container .warehouse .warehouse-info p,
.warehouses-container .warehouse .warehouse-info a {
  font-size: 1.35rem;
  text-transform: capitalize;
}
.warehouses-container .warehouse .warehouse-info .warehouse-location {
  background-color: rgb(255, 131, 3);
  width: fit-content;
  padding: 0.5rem 1rem;
  color: white;
  border-radius: 10px;
}
@media (max-width: 991px) {
  .warehouses-container .warehouse .warehouse-info p,
  .warehouses-container .warehouse .warehouse-info a {
    font-size: 0.9rem;
  }
}
@media (max-width: 700px) {
  .content-container {
    padding: 2rem;
  }
  .warehouses-container .warehouse {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .warehouses-container .warehouse .location-link-div {
    margin: 0 auto;
  }
  .warehouses-container .warehouse .warehouse-info > * {
    margin-bottom: 1.25rem;
  }
  .warehouses-container .warehouse .warehouse-swiper {
    margin-top: 1rem;
    width: 75%;
  }
}
</style>
